import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import logoImg from '../img/logo.png'
import fulllogoImg from '../img/logo-full.png'
import SavingsIcon from '@mui/icons-material/Savings';
import CampaignIcon from '@mui/icons-material/Campaign';




function Sidebar() {

    const handleToggle = () => {
        console.log('clicked')
        document.body.classList.toggle('toggle-sidebar');
    }

    return (
        <>
            <aside className="sidebar">
                <div className='sidebar-line'>
                    <div className="sidebar-logo d-flex align-items-center justify-content-between">
                        <a href="#sec" class="header-logo">
                            <img src={fulllogoImg} className='big-logo' width="150px" alt='logo' />
                            <img src={logoImg} className='mini-logo' height="40px" width="40px" alt='logo' />
                        </a>
                        <div className="menu-bt-sidebar" style={{cursor : "pointer"}} onClick={()=>handleToggle()}>
                            <MenuOpenOutlinedIcon sx={{ color: "white" }} fontSize="large" />
                        </div>
                    </div>
                </div>
                <div className='sidebar-menu'>
                    <ul className="sidebar-menu-nav">
                        <li>
                            <a href="https://easypays.io/" target='_blank' rel="noreferrer">
                                <DashboardIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Website</span>
                            </a>
                        </li>
                        <li>
                            <a href="#sec" className='active'>
                                <EmojiEventsIcon sx={{ color: "rgb(239, 212, 141)" }} className='me-2' />
                                <span>Invest</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://pancakeswap.finance/" target='_blank' rel="noreferrer">
                                <LayersOutlinedIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>Exchange</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=JMppzWrs5M8" target='_blank' rel="noreferrer">
                                <SavingsIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>How to invest</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=jGWRdPu7lGk" target='_blank' rel="noreferrer">
                                <CampaignIcon sx={{ color: "#fff" }} className='me-2' />
                                <span>How to claim</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>
        </>
    )
}
export default Sidebar