import React from 'react';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import ConnectButton from './ConnectButton';
import logoImg from '../img/logo.png';
import iconFree from '../img/live.gif';
import bnbImg from '../img/bnb-logo.png'
import { formatPrice } from '../helper/functions';

export default function Header({ accStats }) {
    const handleToggle = () => {
        document.body.classList.toggle('toggle-sidebar');
    }
    return (
        <>
            <div className="header">
                <div className="d-flex justify-content-between">

                    <div className='d-flex'>
                        <div className="menu-bt-sidebar desk-none me-2" onClick={()=>handleToggle()}>
                            <MenuOpenOutlinedIcon sx={{ color: "white" }} fontSize='large' />
                        </div>
                        <img className='d-block d-md-none' src={logoImg} alt='logo' height="40px" width="40px" />
                    </div>
                    <img src={iconFree} alt='icon' height="35px" className='d-none d-md-block mt-1' />
                    <div style={{ width: '70%' }} className='d-none d-md-block'>
                        <gecko-coin-price-marquee-widget locale="en" dark-mode="true" outlined="true" coin-ids="bitcoin,tether,usd-coin,binancecoin,ethereum" initial-currency="usd"></gecko-coin-price-marquee-widget>
                    </div>
                    <div className='d-flex align-items-center'>
                        <img src={bnbImg} alt='icon' height="35px" className='mx-1' />
                        <span className='badge badge-primary fs-6'>{accStats.bnbBalance ? formatPrice(accStats.bnbBalance , 5) : 0}</span>
                    </div>
                    <ConnectButton />
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <img src={iconFree} alt='icon' height="35px" className='d-block d-md-none mt-1 mx-2' />
                <div style={{ width: '90%' }} className='d-block d-md-none'>
                    <gecko-coin-price-marquee-widget locale="en" dark-mode="true" outlined="true" coin-ids="bitcoin,tether,usd-coin,binancecoin,ethereum" initial-currency="usd"></gecko-coin-price-marquee-widget>
                </div>
            </div>
        </>
    )
}
