import React, { useEffect, useState } from 'react'
import { useAdminDirectReferralStats, useAdminStakeHistoryStats } from '../hooks/useBscAccount';
import { ClipLoader } from 'react-spinners';
import { DECIMALS, EXPLOER } from '../helper/constant';
import { formatPrice, getWeb3, shorterDate, trimAddress } from '../helper/functions';
import { toast } from 'react-toastify';

export default function UserHistory() {
    const [userAddress, setUserAddress] = useState('');
    const [tempAddress, setTempAddress] = useState('')
    const [page, setPage] = useState(1)
    const [level, setLevel] = useState(1)
    const stats = useAdminDirectReferralStats(page, level, userAddress);
    const web3 = getWeb3();
    const [hpage, setHPage] = useState(1)
    const stakeStats = useAdminStakeHistoryStats(userAddress, page)

    useEffect(() => {
        setPage(1)
    }, [level])

    const override = {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto"
    };

    const handleSubmit = () => {
        let check = web3.utils.isAddress(tempAddress);
        if (check) {
            setUserAddress(tempAddress);
        }
        else {
            toast.error('Please enter valid user address');
        }
    }

    return (
        <>
            <div className='container mt-5'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-12 col-md-6'>
                        <h3 className='text-center'>User Info</h3>
                        <input className='form-control' name="address" value={tempAddress} onChange={(e) => setTempAddress(e.target.value)} />
                        <button className='btn btn-primary my-2' onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </div>
                <div className='row mt-5 mb-5 d-flex justify-content-center'>
                    <div className='col-12'>
                        <div className='table-responsive'>
                            <table class="table table-bordered border-primary table-unstake">
                                <thead>
                                    <tr>
                                        <th scope="col">Invest</th>
                                        <th scope="col">Plan($)</th>
                                        <th scope="col">APY</th>
                                        <th scope="col">Start</th>
                                        <th scope="col">End</th>
                                        <th scope="col">Earn</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stakeStats.loading ? (
                                        <tr>
                                            <td colSpan={6} className='p-4'>
                                                <ClipLoader
                                                    color="#fff"
                                                    loading={true}
                                                    cssOverride={override}
                                                    size={50}
                                                // className="spinner"
                                                />
                                            </td>
                                        </tr>
                                    ) : (
                                        stakeStats.stakeHistory && stakeStats.stakeHistory.length > 0 ? (
                                            stakeStats.stakeHistory.map((row, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{row._amount ? formatPrice(row._amount / Math.pow(10, 18)) : 0}</td>
                                                        <td>${row._amountUsd ? formatPrice(row._amountUsd / Math.pow(10, 18)) : 0}</td>
                                                        <td>{row._apy ? formatPrice(row._apy / Math.pow(10, 2), 3) : 0}%</td>
                                                        <td>{row._startTime ? shorterDate(row._startTime * 1000) : ' - '}</td>
                                                        <td>{row._endTime ? shorterDate(row._endTime * 1000) : ' - '}</td>
                                                        <td>{row._claimedReward && row._claimedReward > 0 ? formatPrice(row._claimedReward / Math.pow(10, 18)) : 0}</td>
                                                    </tr>
                                                )
                                            })

                                        ) : (
                                            <tr>
                                                <td colSpan={6}>
                                                    <h5 className="p-5 text-white text-center">You Have No Earn Record Yet.</h5>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className='d-flex justify-content-end mb-3'>
                                {
                                    parseInt(hpage) > 1 &&

                                    <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                        setHPage(hpage - 1);

                                    }}>
                                        Prev
                                    </button>


                                }
                                {parseInt(hpage) < parseInt(stakeStats.totalPages) &&

                                    <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                        setHPage(hpage + 1);

                                    }}>
                                        Next
                                    </button>

                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 mb-5 d-flex justify-content-center ref-history'>
                    <div className='col-12'>
                        <div>
                            <div className='card-header'>
                                <div className='d-md-flex justify-content-between'>
                                    <h3 className='mb-2 mb-md-0'>Referral History</h3>
                                    <div>
                                        <button onClick={() => setLevel(1)} className={`btn btn-outline-warning ${level === 1 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.1({stats && stats.levelcount && stats.levelcount[0] ? stats.levelcount[0] : 0})</button>
                                        <button onClick={() => setLevel(2)} className={`btn btn-outline-warning ${level === 2 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.2({stats && stats.levelcount && stats.levelcount[1] ? stats.levelcount[1] : 0})</button>
                                        <button onClick={() => setLevel(3)} className={`btn btn-outline-warning ${level === 3 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.3({stats && stats.levelcount && stats.levelcount[2] ? stats.levelcount[2] : 0})</button>
                                        <button onClick={() => setLevel(4)} className={`btn btn-outline-warning ${level === 4 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.4({stats && stats.levelcount && stats.levelcount[3] ? stats.levelcount[3] : 0})</button>
                                        <button onClick={() => setLevel(5)} className={`btn btn-outline-warning ${level === 5 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.5({stats && stats.levelcount && stats.levelcount[4] ? stats.levelcount[4] : 0})</button>
                                    </div>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table class="table table-bordered border-primary">
                                    <thead>
                                        <tr>
                                            <th scope="col">Invest</th>
                                            <th scope="col">Start</th>
                                            <th scope="col">APY</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Sharing Income</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stats.loading ? (
                                            <tr>
                                                <td colSpan={7} className='p-4'>
                                                    <ClipLoader
                                                        color="#fff"
                                                        loading={true}
                                                        cssOverride={override}
                                                        size={50}
                                                    // className="spinner"
                                                    />
                                                </td>
                                            </tr>
                                        ) :

                                            stats.data && stats.data.length > 0 ? (stats.data.map((items, key) => {

                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            {items.depositAmount ? formatPrice(items.depositAmount / Math.pow(10, 18)) : 0}
                                                        </td>
                                                        <td>
                                                            {shorterDate(items.startTime * 1000)}
                                                        </td>
                                                        <td>
                                                            {items.apy / 100}%
                                                        </td>

                                                        <td>
                                                            <a href={`${EXPLOER}/address/${items.from}`} target='_blank' rel="noreferrer">
                                                                <span className='mx-1'>{trimAddress(items.from, 3)}</span>
                                                            </a>
                                                        </td>
                                                        <td>
                                                            {formatPrice(items.referralAmount / Math.pow(10, DECIMALS))} USDT
                                                        </td>
                                                        <td>
                                                            {items.sharingreward / Math.pow(10, 18)} USDT
                                                        </td>
                                                    </tr>
                                                )
                                            })) : (
                                                <tr>
                                                    <td colSpan={6} className='text-center'>No Record Found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>

                                </table>
                                <div className='d-flex justify-content-end mb-3'>
                                    {
                                        parseInt(page) > 1 &&

                                        <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                            setPage(page - 1);

                                        }}>
                                            Prev
                                        </button>


                                    }
                                    {parseInt(page) < parseInt(stats.totalPages) &&

                                        <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                            setPage(page + 1);

                                        }}>
                                            Next
                                        </button>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
