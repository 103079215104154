import { useEffect, useState } from "react"
import bscstakeAbi from '../json/bscstake.json';
import bankAbi from '../json/bank.json';
import { toast } from "react-toastify";
import { DECIMALS, DEFAULT_CHAIN, contract } from "../helper/constant";
import { getMultiCall } from "../helper/contractHelper";
import { getWeb3 } from "../helper/functions";
import { ethers } from "ethers";



export const useCommonStats = (updater) => {
    let web3 = getWeb3();
    // const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed2.defibit.io");
    const [stats, setStats] = useState({
        totalStaked: 0,
        totalStakedUsd: 0,
        totalUnstaked: 0,
        totalDistributed: 0,
        stakersCount: 0,
        avrageStake: 0,

        paused: false,
        wpaused: false,
        cpaused: false,
        defaultReferral: 0,
        claimPeriod: 0,
        RefLevel: [],
        stakeAmounts: [],
        perDay: [],
        sharingPer: [],
        stakeApy: [],
        tokenPrice: 0
    });

    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);
    useEffect(() => {
        const fetch = async () => {
            
            try {
                const data = await getMultiCall([
                    stakeContract.methods.getContractStatsInfo(), //0
                    stakeContract.methods.getContractInfo(), //1
                ]);

                setStats({
                    totalStaked: data[0][0] / Math.pow(10, DECIMALS),
                    totalStakedUsd: data[0][1] / Math.pow(10, DECIMALS),
                    totalUnstaked: data[0][2] / Math.pow(10, DECIMALS),
                    totalDistributed: data[0][3] / Math.pow(10, DECIMALS),
                    stakersCount: data[0][5],
                    avrageStake: (data[0][0] / Math.pow(10, DECIMALS)) / data[0][4],


                    paused: data[1][2],
                    wpaused: data[1][3],
                    cpaused: data[1][4],
                    defaultReferral: data[1][5],
                    claimPeriod: data[1][6],
                    RefLevel: data[1][10],
                    stakeAmounts: data[1][8],
                    perDay: data[1][9],
                    sharingPer: data[1][10],
                    stakeApy: data[1][11],
                    tokenPrice: data[1][12] / Math.pow(10, 18)
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater]);

    return stats;
}
