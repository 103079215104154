import React, { useMemo } from 'react'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { formatPrice } from '../helper/functions';
import LockIcon from '@mui/icons-material/Lock';
import { START_TIME, SYMBOL } from '../helper/constant';

export default function TopCards({ commonStats }) {


    return (
        <div className='row mt-4'>
            <div className='col-sm-12 col-md-6 col-lg-3'>
                <div className='box-card back1-top'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <MonetizationOnIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1 text-black'>WECOIN price</h6>
                                <p className='text-black'>${commonStats.tokenPrice ? formatPrice(commonStats.tokenPrice) : 0}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3'>
                <div className='box-card back2-top'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <RecordVoiceOverIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1 text-black'>Smart Contract</h6>
                                {/* <p className='text-black'>${commonStats.avrageStake ? formatPrice(commonStats.avrageStake) : 0}</p> */}
                                <p className='text-black'>AI</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3'>
                <div className='box-card back3-top'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <LockIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1 text-black'>Yield farming</h6>
                                <p className='text-black'>Support</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3'>
                <div className='box-card back1-top'>
                    <div className='box-card-body'>
                        <div className='d-flex'>
                            <div className='icon-box'>
                                <SavingsIcon sx={{ color: "#fff" }} fontSize="large" />
                            </div>
                            <div className='ms-3'>
                                <h6 className='mb-1 text-black'>Current APY</h6>
                                <p className='text-black'>UPTO 200%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function daysSince(starttime) {
    // Get the timestamp for the input date
    const currentTime = new Date().getTime() / 1000;
    // Get the timestamp for today (set time to midnight for accurate difference)
    const days = Math.floor((currentTime - starttime) / 86400);

    return days;
}
