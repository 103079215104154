import React, { useState } from 'react'
import { DEFAULT_CHAIN, contract } from '../helper/constant';
import { formatPrice, getWeb3, shorterDate } from '../helper/functions';
import stakeAbi from '../json/bscstake.json';
import { useAccount, useNetwork } from 'wagmi';
import { getContract } from '../helper/contractHelper';
import { toast } from 'react-toastify';
import { useEthersSigner } from '../hooks/useEthersProvider';
import TabButton from './TabButton';
import MyWallet from './MyWallet';
import { useStakeStats } from '../hooks/useBscAccount';
import { ClipLoader } from 'react-spinners';


export default function Unstake({ accStats, updater, setTab, tab, commonStats }) {
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork()
    const [tLoading, setTLoading] = useState(['', false]);
    const signer = useEthersSigner()
    const [page, setPage] = useState(1)
    const stakeStats = useStakeStats(updater, page)



    const override = {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto"
    };

    const handleTerminate = async (index) => {
        setTLoading([index, true]);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {
                    let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.withdraw(index, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setTLoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setTLoading(['', false]);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setTLoading(['', false]);
        }
    }

    const handleClaim = async (index, type) => {
        setTLoading([index, true]);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {
                    let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);
                    let tx;
                    if (type === 1) {
                        tx = await stakeContract.claim(index, { 'from': address });
                    }
                    else {
                        tx = await stakeContract.claimSharing(index, { 'from': address });
                    }

                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                window.location.reload()
                                setTLoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setTLoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setTLoading(['', false]);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setTLoading(['', false]);
        }
    }


    return (
        <div className='d-flex justify-content-center mb-4 row' style={{ marginLeft: 0 }}>
            <div className='col-12 col-xl-7 card'>
                <TabButton setTab={setTab} tab={tab} />
                <div className='table-responsive'>
                    <table class="table table-bordered border-primary table-unstake">
                        <thead>
                            <tr>
                                <th scope="col">Invest</th>
                                <th scope="col">Plan($)</th>
                                <th scope="col">APY</th>
                                <th scope="col">Reward</th>
                                <th scope="col">Start</th>
                                <th scope="col">Time(D)</th>
                                <th scope="col">Earn</th>
                                <th scope="col">ROI</th>
                                <th scope="col">Sharing</th>
                                <th scope="col">WCCoin</th>

                            </tr>
                        </thead>
                        <tbody>
                            {stakeStats.loading ? (
                                <tr>
                                    <td colSpan={10} className='p-4'>
                                        <ClipLoader
                                            color="#fff"
                                            loading={true}
                                            cssOverride={override}
                                            size={50}
                                        // className="spinner"
                                        />
                                    </td>
                                </tr>
                            ) : (
                                stakeStats.stakeHistory && stakeStats.stakeHistory.length > 0 ? (
                                    stakeStats.stakeHistory.map((row, index) => {
                                        let roi_reward = stakeStats.userStakeReward && stakeStats.userStakeReward[index] ? stakeStats.userStakeReward[index] / Math.pow(10, 18) : 0
                                        let sharing_reward = stakeStats.userStakeSharing && stakeStats.userStakeSharing[index] ? stakeStats.userStakeSharing[index] / Math.pow(10, 18) : 0
                                        let claim_avalible = parseFloat(row._lastclaimed) + parseFloat(commonStats.claimPeriod) <= Math.floor(new Date().getTime() / 1000.0)
                                        let sharing_avalible = parseFloat(row._shareclaimed) + parseFloat(commonStats.claimPeriod) <= Math.floor(new Date().getTime() / 1000.0)
                                        let is_claim_roi = claim_avalible && roi_reward > 0;
                                        let is_sharing_roi = sharing_avalible && sharing_reward > 0;
                                        let totalreward = parseFloat(roi_reward) + parseFloat(sharing_reward);


                                        return (
                                            <tr key={index}>
                                                <td>{row._amount ? formatPrice(row._amount / Math.pow(10, accStats.tokenDecimals)) : 0}</td>
                                                <td>${row._amountUsd ? formatPrice(row._amountUsd / Math.pow(10, accStats.tokenDecimals)) : 0}</td>
                                                <td>{row._apy ? formatPrice(row._apy / Math.pow(10, 2), 3) : 0}%</td>
                                                <td>
                                                    <span style={{ color: "#00B259", fontWeight: "700" }}>
                                                        {totalreward ? formatPrice(totalreward, 8) : 0}
                                                    </span>
                                                </td>
                                                <td>{row._startTime ? shorterDate(row._startTime * 1000) : ' - '}</td>
                                                <td>{getDaysDifference(row._startTime)}</td>
                                                <td>{row._claimedReward && row._claimedReward > 0 ? formatPrice(row._claimedReward / Math.pow(10, 18)) : 0}</td>
                                                <td>
                                                    <button onClick={() => handleClaim(index, 1)} disabled={!is_claim_roi} type="button" className="theme-btn1">
                                                        {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Claim'}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button onClick={() => handleClaim(index, 2)} disabled={!is_sharing_roi} type="button" className="theme-btn1">
                                                        {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Claim'}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button onClick={() => handleTerminate(index, 2)} disabled={parseFloat(row._claimedReward / Math.pow(10, 18)) >= parseFloat(row._totalReward / Math.pow(10, 18)) ? false : true} type="button" className="theme-btn1">
                                                        {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Withdraw'}
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })

                                ) : (
                                    <tr>
                                        <td colSpan={10}>
                                            <h5 className="p-5 text-white text-center">You Have No Earn Record Yet.</h5>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className='d-flex justify-content-end mb-3'>
                        {
                            parseInt(page) > 1 &&

                            <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                setPage(page - 1);

                            }}>
                                Prev
                            </button>


                        }
                        {parseInt(page) < parseInt(stakeStats.totalPages) &&

                            <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                setPage(page + 1);

                            }}>
                                Next
                            </button>

                        }
                    </div>
                </div>
            </div>
            <MyWallet accStats={accStats} />
        </div>

    )
}


function getDaysDifference(oldTimestamp) {
    const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in milliseconds
    const millisecondsInADay = 86400;  //60 * 60 * 24; // 86,400,000 ms in a day

    // Calculate the difference in milliseconds and convert to days
    const differenceInMilliseconds = currentTimestamp - oldTimestamp;
    const daysDifference = Math.floor(differenceInMilliseconds / millisecondsInADay);

    return daysDifference;
}
