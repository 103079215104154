import { bsc } from "wagmi/chains";


export const contract = {
  97: {
    STAKE_ADDRESS: "0x2f16d57fef7021e15a75229b85124fb5c332a7a4",
    MULTICALL_ADDRESS: "0xa54fE4a3DbD1Eb21524Cd73754666b7E13b4cB18",
    TOKEN_ADDRESS: "0x013147EAB8148937DD959c8BF3759b9aB54373F3",
    USDT_ADDRESS : "0xC7b6278c411564729c02548A5742E6D5788BAc33"
  },
  56: {
    STAKE_ADDRESS: "0x3fe9b9456bbe3d8e3def675ae47e0c64be578097",
    MULTICALL_ADDRESS: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116",
    TOKEN_ADDRESS: "0x1FAB731bcb7C0893AC863165D48DE625c67604F2",
    USDT_ADDRESS : "0x55d398326f99059fF775485246999027B3197955"
  }
}

export const DEFAULT_CHAIN = 56;
export const DEFAULT_RPC = "https://bsc-dataseed1.binance.org/"
// export const DEFAULT_RPC = "https://data-seed-prebsc-2-s1.binance.org:8545/"
export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604";
export const DECIMALS = 18;
export const SYMBOL = 'WC';
export const EXPLOER = 'https://bscscan.com/';
export const chains = [bsc]
export const USDT_IMG = "https://tokens.pancakeswap.finance/images/symbol/usdt.png"
export const AOMZ_IMG = "https://alchemy.mypinata.cloud/ipfs/QmdWpGcCgKMTfj7u1biALjhWk2t7KXaZHC5Nw9ehV7VrAM"
export const START_TIME = 1736337604;





