import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAccount } from 'wagmi';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export default function ReferralShare({ commonStats }) {
    const { address } = useAccount();
    const [referrallink, setReferrallink] = useState(
        "Please connect your wallet"
    );
    const [refcopy, setRefcopy] = useState(false);
    let base_url = `${window.location.origin}/earn?ref=`;



    useEffect(() => {
        if (address) {
            setReferrallink(`${base_url}${address}`);
        } else {
            setReferrallink("Please connect your wallet");
        }
    }, [address, base_url]);
    let uptoRef = commonStats.RefLevel.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue / 100), 0);
    return (
        <div className='row mt-4 mb-5 d-flex justify-content-center'>
            <div className='col-12 col-xl-11 col-lg-11'>
                <div className='card'>
                    <div class="ref-main text-center text-white">
                        <article class="presentation">
                            <h2 class="page-title text-website">Refer Friends.Earn Reward</h2>
                            <p class="description">Earn up to {uptoRef}% commission on every earn.</p>
                        </article>
                        <div class="input-group mx-3 my-3 d-flex justify-content-center">
                            <input type="text" className="ref-input" value={referrallink} readOnly={true} />
                            <span class="input-group-text" id="basic-addon2">
                                <CopyToClipboard text={`${base_url}${address}`} onCopy={() => {
                                    setRefcopy(true);
                                    setTimeout(() => {
                                        setRefcopy(false);
                                    }, 2000)
                                }}>
                                    {refcopy ? (
                                        <ContentPasteIcon sx={{ color: "#fff" }} fontSize="small" />
                                    ) : (
                                        <ContentCopyIcon sx={{ color: "#fff" }} fontSize="small" />

                                    )}
                                </CopyToClipboard>
                            </span>
                        </div>
                        <div class="d-flex flex-wrap justify-content-center">


                            {commonStats.RefLevel && commonStats.RefLevel.length > 0 &&
                                commonStats.RefLevel.map((items, key) => (
                                    <div className="mx-1 my-2 ref-badge" key={key}>
                                        Level {key + 1} {" "} = {" "} {items / 100}%
                                    </div>
                                ))
                            }


                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
